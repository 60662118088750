import '../App.css';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react'
function Service(props) {

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}   >
      <Link  className="service-box" onClick={() => {
        if (props.onClick != null) {
          props.onClick()
        }
      }} to={props.path}>
        <div className="service-image" style={{ backgroundSize: props.size, backgroundImage: `url(${props.image})` }} ></div>
        <Typography style={{width:"95%"}} variant="body1" >
          {props.serviceName}
        </Typography>
      </Link>
    </Grid >
  );
}

export default Service;
