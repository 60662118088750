import '../App.css';
import { Helmet } from "react-helmet-async";
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

function Facility(props) {
  const onClick = () => {
    props.selectTab("/contactos")
  }

  return (
    <div className="centered">
      <Helmet>
        <title>Sobre nós- SCACF STUDIO</title>
        <meta name="description" content="Sobre nós: No SCACF Studio pode contar com o apoio e aconselhamento de profissionais certificados de contabilidade e de gestão com mais de 30 anos de experiência! A qualidade do serviço de contabilidade é fundamental para garantir que as contas da sua empresa dão certo ao final do mês.
    Receba apoio e aconselhamento legal nos mais variados tópicos que necessite – processamento de salários, rendas, auditorias, seguros, entre outros! Mantenha as contas em dia de forma rápida e eficaz, deixando o mundo das finanças para os profissionais do SCACF Studio. Dessa forma, poderá redirecionar a sua energia para um melhor desenvolvimento do seu negócio.
    Seguimos todas as normas da DGS e, portanto, o nosso escritório está aberto para o receber a qualquer momento. Se preferir receber aconselhamento de forma remota, também satisfazemos o seu pedido através de email ou por chamada telefónica.
    O nosso espaço, instalações." />
        <meta name="keywords" content="scacf,sobre,nós,espaço,instalações" />
      </Helmet>
      <div className="tabTitle"></div>
      <Typography variant="h4" color="primary" style={{ width: "100%",paddingTop:"10px", height:"50px",position: "-webkit-sticky", position: "sticky", top: 0, backgroundColor: "white" }}>Quem somos</Typography>

      <Typography gutterBottom className="justified" variant="body1" color="textSecondary">
        <a className="tab" />O SCACF Studio é uma micro empresa que presta serviços de contabilidade e assessoria na gestão de negócios. Aqui pode contar com o apoio e aconselhamento de profissionais certificados com mais de 30 anos de experiência! 
      </Typography>
      <Typography gutterBottom className="justified" variant="body1" color="textSecondary">
        <a className="tab" /> Receba apoio e aconselhamento legal nos mais variados tópicos que necessite – processamento de salários, rendas, auditorias, seguros, entre outros! Mantenha as contas em dia de forma rápida e eficaz, deixando o mundo das finanças para os profissionais do SCACF Studio. Dessa forma, poderá redirecionar a sua energia para um melhor desenvolvimento do seu negócio. </Typography>
      <Typography className="justified" variant="body1" color="textSecondary">
        <a className="tab" /> O SCACF exerce a sua atividade na área do Grande Porto. Seguimos todas as normas da DGS e, portanto, o nosso escritório está aberto para o receber a qualquer momento. Se preferir receber aconselhamento de forma remota, também satisfazemos o seu pedido através de <Link onClick={onClick} to="/contactos">email</Link> ou por <Link onClick={onClick} to="/contactos">chamada telefónica</Link>. </Typography>
      <br />
      <Typography variant="h4" color="primary" style={{ width: "100%",paddingTop:"10px", height:"50px",position: "-webkit-sticky", position: "sticky", top: 0, backgroundColor: "white" }}>O nosso espaço</Typography>
      <Grid container className="grid-images" spacing={1} >
        <Grid item xs={12} md={6} lg={4}>
          <img src="https://drive.google.com/uc?export=view&id=1svBIwOas4hSOneS-Zsey2oUR6o6oJUKA" style={{ width: "100%" }} alt="frente" ></img>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <img src="https://drive.google.com/uc?export=view&id=1KRFTI5xLOLY5khBhdMM7Lsy1EoqEnwVr" style={{ width: "100%" }} alt="fila" ></img>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <img src="https://drive.google.com/uc?export=view&id=1RU2kGyKuYL5hZUpxCRAVfZnj-Dx5s3BS" style={{ width: "100%" }} alt="sofa" ></img>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <img src="https://drive.google.com/uc?export=view&id=1208yhgPHRTCNA8eWZfnIrGUrUqZjvGxZ" style={{ width: "100%" }} alt="regras" ></img>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <img src="https://drive.google.com/uc?export=view&id=1uPN9DXv-ohrAc2piidk3cNt3JPRHld-R" style={{ width: "100%" }} alt="espaço" ></img>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <img src="https://drive.google.com/uc?export=view&id=1EjjJ7Sm8WEy-QEz8nvcbYRFpJbsZkVMn" style={{ width: "100%" }} alt="reunioes" ></img>
        </Grid>
      </Grid>
      <div style={{ width: "100%", height: "10px", position: "-webkit-sticky", position: "sticky", bottom: 0, backgroundColor: "white" }}>&nbsp;</div>
    </div>
  );
}

export default Facility;
