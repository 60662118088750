import '../App.css';
import online from '../images/online.svg'
import arquivo from '../images/arquivo.svg'
import IRSIRC from '../images/IRSIRC.svg'
import ivaies from '../images/ivaies.svg'
import dossier from '../images/dossier.svg'
import plano from '../images/plano.svg'
import serv_cont from '../images/servico_cont.svg'
import RECIBO from '../images/RECIBO.svg'
import doccont from '../images/doccont.svg'
import planfin from '../images/planfin.svg'
import servico_seguro from '../images/servico_seguro.svg'
import ordenado from '../images/ordenado.svg'
import servico_traducoes from '../images/servico_traducoes.svg'
import impostos from '../images/impostos.svg'
import { Helmet } from "react-helmet-async";
import { Route, Switch } from 'react-router-dom';
import Service from './Service';
import { Grid, Typography } from '@material-ui/core';
function Services(props) {
  return (
    <div className="centered" style={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Serviços - SCACF STUDIO</title>
        <meta name="description" content="Serviços: Arquivo digital de documentação, Contabilidade Online, Declarações de IRS, IRC, IVA e IES, Dossier Fiscal Anual, Elaboração de plano financeiro
        Gestão de documentos contabilísticos, Gestão financeira e comercial, Pagamento de impostos, Recibos de vencimento, seguros, traduções. " />
        <meta name="keywords" content="scacf, contabilidade,arquivo,arquivo digital,documentação,online,declaração,IRS,IRC,IVA,IES,dossier fiscal,plano financeiro,gestão,documentos contabilísticos,
        gestão financeira,gestão comercial,impostos,ordenados,vencimento,recibos,faturas,seguradora,seguro,traduções" />
      </Helmet>
      <div className="tabTitle"></div>
      <Switch >
        <Route exact path={props.basePath}>
          <Typography variant="h4" color="primary" style={{zIndex:1, width: "100%", paddingBottom: "10px", height: "50px", position: "-webkit-sticky", position: "sticky", top: 0, backgroundColor: "white" }}>
            Contabilidade
          </Typography>
          <br/>
          <Grid container spacing={3} >
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Arquivo digital de documentação") }} serviceName="Arquivo digital de documentação" image={arquivo}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Contabilidade Online") }} serviceName="Contabilidade Online" image={online}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Declarações de IRS e IRC") }} serviceName="Declarações de IRS e IRC" image={IRSIRC}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Declarações de IVA e IES") }} serviceName="Declarações de IVA e IES" image={ivaies}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Dossier Fiscal Anual") }} serviceName="Dossier Fiscal Anual" image={dossier}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Elaboração de plano financeiro") }} serviceName="Elaboração de plano financeiro" image={plano}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Gestão de documentos contabilísticos") }} serviceName="Gestão de documentos contabilísticos" image={doccont}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Gestão financeira e comercial") }} serviceName="Gestão financeira e comercial" image={planfin}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Pagamento de impostos") }} serviceName="Pagamento de impostos" image={impostos}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Processamento de Ordenados") }} serviceName="Processamento de Ordenados" image={ordenado}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Recibos de vencimento") }} serviceName="Recibos de vencimento" image={RECIBO}></Service>
          </Grid>
          <Typography variant="h4" color="primary" style={{ width: "100%", paddingTop: "10px", paddingBottom: "10px", height: "50px", position: "-webkit-sticky", position: "sticky", top: 0, backgroundColor: "white" }}>
            Outros
          </Typography>
          <Grid container spacing={3} >
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Seguros") }} serviceName="Seguros" image={servico_seguro}></Service>
            <Service size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Traduções") }} serviceName="Traduções" image={servico_traducoes}></Service>
          </Grid>
        <div style={{ width: "100%", height: "10px", position: "-webkit-sticky", position: "sticky", bottom: 0, backgroundColor: "white" }}>&nbsp;</div>
        </Route>
      </Switch>
    </div >
  );
}

export default Services;
