import { Link, useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import { AppBar, Button, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import logo from '../images/scafc_pequeno.png';

function defaultValue(value) {
  return value > -1 ? value : 0
}

function menuImage(value) {
  switch (value) {
    case 0:
      return <FormatListBulletedOutlinedIcon />
    case 1:
      return <ContactPhoneOutlinedIcon />
    case 2:
      return <InfoOutlinedIcon />
    default:
      return <ReceiptOutlinedIcon />
  }
}

const routeNames = ["Serviços", "Contactos", "Sobre Nós", "Orçamento"]

function TabSelector(props) {


  const history = useHistory()
  let loc = useLocation().pathname
  React.useEffect(() => {
    let index = props.routes.indexOf(loc)
    if (index == -1)
      handleChange(0)
    else
      props.setValue(defaultValue(index))
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue) => {
    props.setValue(newValue);
    history.push(props.routes[newValue])
    handleClose()
  };

  return (
    <React.Fragment>
      <AppBar className="menu" color="default">
        <Toolbar className="non-selectable" style={{ position: "relative" }}>

          <Link edge="start" style={{ flexGrow: 1, marginRight: "150px" }} onClick={() => { props.setValue(0) }} to="/">
            <div className="App-logo-sizer"  >
              <img src={logo} className="App-logo" alt="logo" />
            </div>
          </Link>

          {routeNames.map((name, index) =>
            <Link key={index} className="menuCent" onClick={() => { props.setValue(index) }} to={props.routes[index]}>
              <Typography noWrap className="menuOption" variant="body1" color={props.value == index ? "primary" : "textSecondary"}>{props.value == index ? <b>{name}</b> : name}</Typography>
            </Link>
          )}
        </Toolbar></AppBar>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {routeNames.map((value, index) =>
          <MenuItem key={index} selected={props.value === index} onClick={() => handleChange(index)}>
            <ListItemIcon>
              {menuImage(index)}
            </ListItemIcon>
            {value}
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}

export default TabSelector;
