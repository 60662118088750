import '../App.css';
import { useHistory } from 'react-router-dom';
import { Backdrop, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, InputAdornment, makeStyles, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import React from 'react'
import { getCountries, getCountryCallingCode, formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { sendEmail } from '../service/emailService';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Budget(props) {
  const servicos = ["Contabilidade - Arquivo digital de documentação",
    "Contabilidade - Contabilidade Online",
    "Contabilidade - Declarações de IRS e IRC",
    "Contabilidade - Declarações de IVA e IES",
    "Contabilidade - Dossier Fiscal Anual",
    "Contabilidade - Elaboração de plano financeiro",
    "Contabilidade - Gestão de documentos contabilísticos",
    "Contabilidade - Gestão financeira e comercial",
    "Contabilidade - Pagamento de impostos",
    "Contabilidade - Processamento de Ordenados",
    "Contabilidade - Recibos de vencimento",
    "Seguros",
    "Traduções",
    "Outro"]

  const frequencies = ["Único", "Continuado", "Mensal", "Anual", "Outro"]
  const contacts = ["Email", "Telefone"]
  const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


  const classes = useStyles();
  const history = useHistory()

  const toContacts = () => {
    props.selectTab("/contactos")
  }
  const toMain = () => {
    props.selectTab("/")
    history.push("/")
  }


  const tiposCliente = ["Particular", "Empresa", "Sociedade Anónima", "Nova empresa", "Outro"]
  const [otherService, setOtherService] = React.useState([false, ""]);
  const [frequency, setFrequency] = React.useState("");
  const [otherFrequency, setOtherFrequency] = React.useState([false, ""]);
  const [name, setName] = React.useState([false, ""]);
  const [tipoCliente, setTipoCliente] = React.useState(tiposCliente[0]);
  const [otherTipoCliente, setOtherTipoCliente] = React.useState([false, ""]);
  const [organization, setOrganization] = React.useState([false, ""]);
  const [numFunc, setNumFunc] = React.useState();
  const [volFat, setVolFat] = React.useState();
  const [sector, setSector] = React.useState([false, ""]);
  const [contact, setContact] = React.useState("Email");
  const [country, setCountry] = React.useState("PT")
  const [actualContact, setActualContact] = React.useState([false, "", true]);
  const [submitted, setSubmitted] = React.useState(false);
  const [notes, setNotes] = React.useState("");
  const [okModal, setOkModal] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [dataCheck, setDatacheck] = React.useState(false);

  const okFuntion = () => {
    setOkModal(false)
    toMain()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Solicitar Orçamento - SCACF STUDIO</title>
        <meta name="description" content="Serviços: Arquivo digital de documentação, Contabilidade Online, Declarações de IRS, IRC, IVA e IES, Dossier Fiscal Anual, Elaboração de plano financeiro
        Gestão de documentos contabilísticos, Gestão financeira e comercial, Pagamento de impostos e Recibos de vencimento, traduções, seguros" />
        <meta name="keywords" content="scacf,contabilidade,arquivo,arquivo digital,documentação,online,declaração,IRS,IRC,IVA,IES,dossier fiscal,plano financeiro,gestão,documentos contabilísticos,
        gestão financeira,gestão comercial,impostos,ordenados,vencimento,recibos,faturas,seguradora,traduções,seguros" />
      </Helmet>
      <div className="centered" style={{ width: "100%", height: "100%", paddingBottom: "10px" }}>

        <div style={{ width: "100%", paddingTop: "10px", paddingBottom: "10px", zIndex: 1, position: "-webkit-sticky", position: "sticky", top: 0, backgroundColor: "white" }}>
        </div>
        <Grid style={{ width: "100%" }} container spacing={5}>



          <Grid item xs={1} />
          <Grid container item xs={5} spacing={5}>
            <Grid item xs={12} spacing={0}>
              <Typography color="primary" variant="h6">Detalhes de serviço e contacto</Typography></Grid>
            <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Serviço:</Typography>
              <Select id="service"
                autoWidth
                error={submitted && props.selectedService === ""}
                style={{ width: props.selectedService === "Outro" ? "35%" : "100%" }}
                value={props.selectedService}
                onChange={(e) => props.setSelectedService(e.target.value)}
              >
                {servicos.map(option => {
                  return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{option}</MenuItem>)
                })}
              </Select>


              {props.selectedService === "Outro" ?
                <TextField
                  value={otherService[1]}
                  onBlur={() => { setOtherService([true, otherService[1].trim()]) }}
                  onChange={(e) => { setOtherService([true, e.target.value]) }}
                  error={otherService[1] === "" && otherService[0]}
                  id="service"

                  style={{ marginLeft: "2%", width: "63%" }}></TextField>
                : null}
              {submitted ? (
                props.selectedService === "" ?
                  <Typography color="error" variant="caption">*Por favor selecione um serviço</Typography>

                  : (
                    props.selectedService === "Outro" && otherService[1] === "" && otherService[0] ?
                      <Typography color="error" variant="caption">*Por favor explicite o serviço desejado</Typography>
                      :
                      <Typography color="error" variant="caption">&nbsp;</Typography>)
              )
                :
                <Typography color="error" variant="caption">&nbsp;</Typography>
              }
            </Grid>
            <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Frequência do serviço:</Typography>

              <Select id="frequency"
                autoWidth
                error={submitted && frequency === ""}
                style={{ width: frequency === "Outro" ? "35%" : "100%" }}
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
              >
                {frequencies.map(option => {
                  return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{option}</MenuItem>)
                })}
              </Select>
              {frequency === "Outro" ?
                frequency === "Outro" ?
                  <TextField
                    style={{ marginLeft: "2%", width: "63%" }}
                    value={otherFrequency[1]}
                    onBlur={() => { setOtherFrequency([true, otherFrequency[1].trim()]) }}
                    onChange={(e) => { setOtherFrequency([true, e.target.value]) }}
                    error={otherFrequency[1] === "" && otherFrequency[0]}
                    id="frequency"
                  ></TextField> : null
                : null}
              {submitted ? (
                frequency === "" ?
                  <Typography color="error" variant="caption">*Por favor selecione uma frequencia</Typography>
                  :
                  frequency === "Outro" && otherFrequency[1] === "" && otherFrequency[0] ?
                    <Typography color="error" variant="caption">*Por favor explicite a frequência desejada</Typography>
                    : <Typography color="error" variant="caption">&nbsp;</Typography>
              )
                : <Typography color="error" variant="caption">&nbsp;</Typography>
              }

            </Grid>
            <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Pessoa de Contacto:</Typography>

              <TextField
                value={name[1]}
                onBlur={() => { setName([true, name[1].trim()]) }}
                onChange={(e) => { setName([true, e.target.value]) }}
                error={name[1] === "" && name[0]}
                id="name"
                name="name"
                autoComplete="name"
                style={{ width: "100%" }}
              />
              {submitted && name[1] === "" && name[0] ?
                <Typography className="left" color="error" variant="caption">*Por favor indique o nome da pessoa a contactar</Typography> :
                <Typography color="error" variant="caption">&nbsp;</Typography>}

            </Grid>
            <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Contacto:</Typography>
              <Select id="contact"
                autoWidth
                style={{ width: "20%" }}
                value={contact}
                onChange={(e) => { setActualContact([false, "", true]); setContact(e.target.value) }}
              >
                {contacts.map(option => {
                  return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{option}</MenuItem>)
                })}
              </Select>
              {contact === "Email" ?
                <React.Fragment>
                  <TextField
                    defaultValue={actualContact[1]}
                    onBlur={() => { setActualContact([true, actualContact[1].trim(), !pattern.test(actualContact[1].trim())]) }}
                    onChange={(e) => { setActualContact([true, e.target.value, false]) }}
                    error={actualContact[2] && actualContact[0]}
                    id="email"
                    autoComplete="email"

                    style={{ marginLeft: "5%", width: "75%" }}></TextField>
                  {submitted && actualContact[2] && actualContact[0] ?
                    <Typography style={{ marginLeft: "25%" }} color="error" variant="caption">*Por favor indique o email</Typography>
                    : <Typography color="error" variant="caption">&nbsp;</Typography>}
                </React.Fragment> :
                <React.Fragment>

                  <Select
                    id="country"
                    autoComplete="country"
                    autoWidth
                    style={{ marginLeft: "5%", width: "30%" }}
                    value={country}
                    onChange={(e) => { setActualContact([true, "", false]); setCountry(e.target.value) }}
                  >
                    {getCountries().map(option => {
                      return (<MenuItem style={{ overflowX: "auto" }} key={option} value={option}>{en[option]}  +{getCountryCallingCode(option)}</MenuItem>)
                    })}
                  </Select>
                  <TextField
                    value={actualContact[1]}
                    onChange={(e) => {
                      let phoneNumber = "+" + getCountryCallingCode(country) + e.target.value.trim()
                      console.log(phoneNumber);
                      if (isPossiblePhoneNumber(phoneNumber)) {
                        console.log(phoneNumber)
                        setActualContact([true, formatPhoneNumber(phoneNumber), false])
                      }
                      else
                        setActualContact([true, e.target.value.trim(), true])
                    }}
                    error={(actualContact[2] || actualContact[1] === "") && actualContact[0]}
                    id="tel"
                    autoComplete="tel"
                    type="tel"
                    style={{ marginLeft: "2%", width: "43%" }}></TextField>
                  {submitted && (actualContact[2] || actualContact[1] === "") && actualContact[0] ?
                    <Typography style={{ marginLeft: "57%" }} color="error" variant="caption">*Por favor indique o número</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                </React.Fragment>
              }
            </Grid>
            {(props.selectedService === "Traduções") || (tipoCliente === "Particular") ? null : <Grid style={{ height: "100px" }} container item xs={12} spacing={0} />}
          </Grid>

          <Grid item xs={1} />
          <Grid container item xs={5} spacing={5}>
            <Grid item xs={12} spacing={0}>
              <Typography color="primary" variant="h6">Perfil do cliente</Typography></Grid>
            <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Tipo de cliente:</Typography>

              <Select id="tipo-de-cliente"
                autoWidth
                style={{ width: tipoCliente === "Outro" ? "35%" : "100%" }}
                value={tipoCliente}
                error={submitted && tipoCliente === ""}
                onChange={(e) => setTipoCliente(e.target.value)}
              >
                {tiposCliente.map(option => {
                  return (<MenuItem key={option} value={option}>{option}</MenuItem>)
                })}
              </Select>
              {tipoCliente === "Outro" ?
                <TextField
                  value={otherTipoCliente[1]}
                  onBlur={() => { setOtherTipoCliente([true, otherTipoCliente[1].trim()]) }}
                  onChange={(e) => { setOtherTipoCliente([true, e.target.value]) }}
                  error={otherTipoCliente[1] === "" && otherTipoCliente[0]}
                  id="tipo-de-cliente"

                  style={{ marginLeft: "2%", width: "63%" }}></TextField> : null}
              {submitted && tipoCliente === "Outro" && otherTipoCliente[1] === "" && otherTipoCliente[0] ?
                <Typography color="error" variant="caption">*Por favor explicite a o tipo de organização</Typography>
                : <Typography color="error" variant="caption">&nbsp;</Typography>

              }
            </Grid>


            {tipoCliente === "Particular" ? null : <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Nome da {tipoCliente !== "Outro" ? tipoCliente : "Organização"}:</Typography>

              <TextField
                value={organization[1]}
                onBlur={() => { setOrganization([true, organization[1].trim()]) }}
                onChange={(e) => { setOrganization([true, e.target.value]) }}
                error={organization[1] === "" && organization[0]}
                id="organization"
                autoComplete="organization"
                style={{ width: "100%" }}
              />

              {organization[1] === "" && organization[0] ?
                <Typography color="error" variant="caption">*Por favor indique o nome da organização</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}

            </Grid>
            }

            <Grid style={{ height: "100px" }} item container xs={12} spacing={0}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Setor de atividade:</Typography>

              <TextField
                value={sector[1]}
                onBlur={() => { setSector([true, sector[1].trim()]) }}
                onChange={(e) => { setSector([true, e.target.value]) }}
                error={sector[1] === "" && sector[0]}
                id="sector"
                style={{ width: "100%" }}
              />
              {submitted && sector[1] === "" && sector[0] ?
                <Typography color="error" variant="caption">*Por favor indique o setor de atividade</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
            </Grid>

            {props.selectedService === "Traduções" ? tipoCliente !== "Particular" ?
              <Grid style={{ height: "100px" }} container item xs={12} spacing={0} /> : <React.Fragment>
                <Grid style={{ height: "100px" }} container item xs={12} spacing={0} />
                <Grid style={{ height: "100px" }} container item xs={12} spacing={0} />
              </React.Fragment> : <React.Fragment>
                <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
                  <Typography className="left" variant="subtitle1" color="textSecondary">Número de funcionários:</Typography>

                  <TextField
                    error={submitted && isNaN(parseInt(numFunc))} style={{ width: "100%" }}
                    defaultValue={null} onChange={(e) => { let nan = isNaN(parseInt(e.target.value.trim())); setNumFunc((nan || nan ? null : parseInt(e.target.value.trim()) < 0) ? 0 : parseInt(e.target.value.trim())) }} type="number" inputProps={{ min: 0, step: 1 }} />
                  {submitted && isNaN(parseInt(numFunc)) ?
                    <Typography color="error" variant="caption">*Por favor indique o número de funcionários</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                </Grid>

                <Grid style={{ height: "100px" }} container item xs={12} spacing={0}>
                  <Typography className="left" variant="subtitle1" color="textSecondary">Volume de faturação:</Typography>
                  <TextField style={{ width: "100%" }}
                    id="volume-faturacao"
                    error={submitted && isNaN(parseInt(volFat))}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}

                    defaultValue={null} onChange={(e) => { let nan = isNaN(parseInt(e.target.value.trim())); setVolFat((nan || nan ? null : parseInt(e.target.value.trim()) < 0) ? 0 : parseInt(e.target.value.trim())) }} type="number" inputProps={{ min: 0, step: 1 }} />
                  {submitted && isNaN(parseInt(volFat)) ?
                    <Typography color="error" variant="caption">*Por favor indique o volume de faturação</Typography> : <Typography color="error" variant="caption">&nbsp;</Typography>}
                </Grid>
              </React.Fragment>
            }
          </Grid>


          <Grid item xs={12} container spacing={5}>

            <Grid item xs={1} spacing={0} />
            <Grid item xs={11} spacing={5}>
              <Typography className="left" variant="subtitle1" color="textSecondary">Notas:</Typography>
              <TextField
                multiline
                placeholder="Caso exista alguma incerteza quanto a alguma das respostas ou qualquer outra questão, pode fazê-la aqui."
                defaultValue={""}
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                variant="outlined"
                style={{ marginLeft: "-2.5%", width: "97.5%" }}></TextField>
              <div style={{ display: "block", width: "100%" }}>
                <Checkbox color="primary" style={{ float: "left" }} onChange={(e) => setDatacheck(e.target.checked)} checked={dataCheck} />
                <Typography color={submitted && !dataCheck ? "error" : "textPrimary"} variant="body2" className="justified" style={{ float: "left", marginTop: "10px", width: "85%" }} onClick={() => setDatacheck(!dataCheck)}>  Declaro que os meus dados podem ser utilizados para efeitos de comunicação e marketing da SCACF Studio.</Typography>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={5}>

          <Grid item xs={1} spacing={0}></Grid>
            <Grid item xs={11} spacing={0}>
              <Button hidden onClick={() => {
                setSubmitted(true)


                let valid = dataCheck
                if (props.selectedService === "Outro") {
                  setOtherService([true, otherService[1]])
                  valid = valid && otherService[1] !== ""
                }

                valid = valid && props.selectedService !== ""


                if (frequency === "Outro") {
                  setOtherFrequency([true, otherFrequency[1]])
                  valid = valid && otherFrequency[1] !== ""
                }

                valid = valid && frequency !== ""

                setName([true, name[1]])
                valid = valid && name[1] !== ""


                if (tipoCliente === "Outro") {
                  setOtherService([true, otherTipoCliente[1]])
                  valid = valid && otherTipoCliente[1] !== ""
                }

                valid = valid && tipoCliente !== ""


                setOrganization([true, organization[1]])
                valid = valid && (tipoCliente === "Particular" || tipoCliente === "Outro" || organization[1] !== "")

                setSector([true, sector[1]])
                valid = valid && sector[1] !== ""

                setActualContact([true, actualContact[1], actualContact[2]])
                valid = valid && actualContact[1] !== "" && !actualContact[2]
                console.log(actualContact[2])

                if (valid) {
                  setShowBackdrop(true)
                  sendEmail({
                    name: name[1],
                    client: tipoCliente === "Outro" ? "(Outro) " + otherTipoCliente[1] : tipoCliente,
                    organization: organization[1],
                    sector: sector[1],
                    employees: numFunc == null ? "Não especificado" : numFunc,
                    billing: volFat == null ? "Não especificado" : volFat,
                    service: props.selectedService === "Outro" ? "(Outro) " + otherService[1] : props.selectedService,
                    frequency: frequency === "Outro" ? "(Outro) " + otherFrequency[1] : frequency,
                    contact: (contact === "Telefone" ? "(+" + getCountryCallingCode(country) + ") " : "") + actualContact[1],
                    notes: notes,
                  }, (e) => {
                    setShowBackdrop(false);
                    setOkModal(true)
                  }, (e) => {
                    setShowBackdrop(false)
                    setErrorModal(true)
                  })
                }
                else { console.log("yikes") }
              }} className="link" style={{ marginLeft: "-2.5%", width: "97.5%"  }} variant="contained" color="primary">
                Solicitar Orçamento
</Button>
            </Grid>
          </Grid>
        </Grid >


        <div style={{ marginTop: "auto" }}></div>

        <Dialog onClose={okFuntion} aria-labelledby="customized-dialog-title" open={okModal}>
          <DialogContent  >
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <CheckCircleIcon style={{ height: "100%", width: "100%" }} color="primary" />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={9}>
                <Typography variant="body1" style={{height:"100%"}} className="left">
                  O seu pedido de orçamento foi registado. Será contactado brevemente através do {contact.toLowerCase()} disponibilizado!
          </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={okFuntion} color="primary">
              ok
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog onClose={() => setErrorModal(false)} open={errorModal}>
          <DialogContent >
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <ErrorIcon style={{ height: "100%", width: "100%" }} color="error" />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={9}>
                <Typography variant="body1" style={{height:"100%"}} className="left">
                  <a >
                    Algo inesperado aconteceu. Tente novamente mais tarde ou <Link className="link" onClick={toContacts} to="/contactos">contacte-nos</Link> diretamente.</a>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrorModal(false)} color="primary">
              ok
          </Button>
          </DialogActions>
        </Dialog>

        <Backdrop className={classes.backdrop} open={showBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div style={{ width: "100%", height: "10px", position: "-webkit-sticky", position: "sticky", bottom: 0, backgroundColor: "white" }}>&nbsp;</div>
      </div >
    </React.Fragment >
  );
}

export default Budget;
