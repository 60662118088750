import '../App.css';
import { Helmet } from "react-helmet-async";
import {  Grid, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';


function Contacts() {
  return (
    <div className="centered">
      <Helmet>
        <title>Contactos - SCACF STUDIO</title>
        <meta name="description" content="Modada: Rua do Horizonte 1549 RC, S. Romão do Coronado,
        4745-531 Trofa, Porto. Telefone: +351 229 864 330, Telemóvel: +351 932 025 698, email: scacf.studio@gmail.com
        " />
        <meta name="keywords" content="scacf,contactos,telefone,telemovel,morada,email" />
      </Helmet>
      <div className="tabTitle"></div>
      <Grid style={{ marginBottom: "2%" }} className="grid-images" container>

        <Grid item xs={12}>
         <iframe title="scacf-address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.3237415310086!2d-8.559163291184605!3d41.28005810860999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24616b6edbaa9d%3A0xd0d5828aad8713ec!2sSCACF%20Studio!5e0!3m2!1sen!2spt!4v1610103582318!5m2!1sen!2spt"
            style={{ width: "100%", height:"calc(80vh - 210px)",frameborder: "0", border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        </Grid>
        
        <Grid className="spacer" item xs={12}></Grid>
        <Grid item xs={1}/>
        <Grid item xs={5} className="left">
          <Typography variant="h6" >
            Rua do Horizonte 1549 RC
            </Typography>
          <Typography variant="body1" color="textSecondary">
            S. Romão do Coronado
              </Typography>
          <Typography variant="body1" color="textSecondary">
            4745-531
              </Typography>
          <Typography variant="body1" color="textSecondary">
            Trofa
            </Typography>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={5}>
          <div className="left">

            <Typography variant="h6" component="h5">
              Vias de contacto
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <PhoneIcon color="primary" style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="tel:+351229864330">+351 229 864 330</a> 
              </Typography>
            <Typography variant="body1" color="textSecondary">
              <PhoneIcon color="primary" style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link"href="tel:+351932025698">+351 932 025 698</a>
              </Typography>
            <Typography variant="body1" color="textSecondary">
              <AlternateEmailIcon color="primary" style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="mailto:scacf.studio@gmail.com">scacf.studio@gmail.com</a>
              </Typography>
          </div>
        </Grid>
      </Grid>

    </div >
  );
}

export default Contacts;
