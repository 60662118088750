import logo from './images/scacf_grande.png';
import './App.css';
import Facility from './facility/Facility';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TabSelector from './facility/Selector';
import { HelmetProvider } from 'react-helmet-async'
import Contacts from './facility/Contacts';
import Services from './facility/Services';
import Budget from './facility/Budget';
import { Grid, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import BusinessIcon from '@material-ui/icons/Business';


const routeMap = [
  "/",
  "/contactos",
  "/sobre",
  "/orcamento"
]

function App() {
  const [value, setValue] = React.useState(0);
  const [selectedService, setSelectedService] = React.useState("");

  return (
    <HelmetProvider>
      <div className="App">
        <div className="App-header">
          <Router>
            <TabSelector className="menu" value={value} setValue={setValue} routes={routeMap} />
            <Paper className="mainPaper">
              <Switch>
                <Route path="/contactos">
                  <Contacts />
                </Route>
                <Route path="/sobre">
                  <Facility selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
                <Route path="/orcamento">
                  <Budget setSelectedService={setSelectedService} selectedService={selectedService} selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
                <Route path="/servicos">
                  <Services setSelectedService={setSelectedService} basePath="/servicos" selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
                <Route path='*'>
                  <Services setSelectedService={setSelectedService} basePath="/" selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
              </Switch>
            </Paper>
          </Router>
          <Grid container style={{ zIndex:1, marginTop: "20px", width: "80%" }}>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <div className="left" >
                <Typography variant="body2" color="textSecondary">
                  <BusinessIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="https://goo.gl/maps/nKpzCm8XAhrCTzsq5" target="_blank">Rua do Horizonte 1549 RC, 4745-531 Trofa</a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <AlternateEmailIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="mailto:scacf.studio@gmail.com">scacf.studio@gmail.com</a>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div className="left" >

                <Typography variant="body2" color="textSecondary">
                  <PhoneIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="tel:+351229864330">+351 229 864 330</a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <PhoneIcon style={{ marginBottom: "-5px" }} fontSize="small" /> <a className="link" href="tel:+351932025698">+351 932 025 698</a>
                </Typography>
              </div>
            </Grid>
          </Grid>
          <div style={{ backgroundSize: "3%", backgroundImage: `url(${logo})` }} className="background"></div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
