export function sendEmail( body, afterResponse, onError) {
    const http = require('http')
    const data =JSON.stringify(body)
    const options = {
        hostname: "us-central1-scacf-email.cloudfunctions.net",
        //hostname:"localhost",
        //port:5001,
        path: "/app/sendEmail",
        //path:"/scacf-email/us-central1/app/sendEmail",
        method: 'POST',
        headers: {
            'Access-Control-Request-Method': 'POST',
            'Access-Control-Request-Headers': 'Content-Type,Authorization',
            'Content-Type': 'application/json',
            'Content-Length': data.length
        },
        insecureHTTPParser: true

    }

    const req = http.request(options, res => {
        console.log(`statusCode: ${res.statusCode}`)
        var body = '';
        res.on('data', function (chunk) {
            body += chunk;
        });
        res.on('end', function () {
            if (res.statusCode >= 400) {
                onError()
            }
            else
                afterResponse(body);
        });
    })

    req.on('error', error => {
        onError()
    })

    req.write(data)
    req.end()

}